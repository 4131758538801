import React, { Component } from "react";
import "./divider.css";
import img from "../../assests/images/divider.png";

class Divider extends Component {
  render() {
    return (
      <div className="divider-container">
        <img className="divider-img" src={img} alt="divider" />
      </div>
    );
  }
}

export default Divider;
