import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import ExperienceAccordion from "../../containers/experienceAccordion/ExperienceAccordion.js";
import "./Experience.css";
import { experience } from "../../portfolio.js";
import { Fade } from "react-reveal";
import { withTranslation } from "react-i18next";
import StudiesSection from "../../components/studiesSection/StudiesSection.js";
// import ExperienceImg from "./ExperienceImg";

class Experience extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;
    return (

      <div>
        <div className="experience-main">
          <Header theme={this.props.theme} />
          <div className="basic-experience">
            <Fade bottom duration={2000} distance="40px">

              <div className="experience-heading-div">
                {experience["header_image_path"] ? (
                  <div className="experience-heading-img-div">
                    <img
                      src={require(`../../assests/images/${experience["header_image_path"]}`)}
                      alt=""
                    />
                    {/* <ExperienceImg theme={theme} /> */}
                  </div>
                ) : null}
                <div className="experience-heading-text-div">
                  <h1
                    className="experience-heading-text"
                    style={{ color: theme.text }}
                  >
                    {t(experience.title)}
                  </h1>
                  <h3
                    className="experience-heading-sub-text"
                    style={{ color: theme.text }}
                  >
                    {t(experience["subtitle"])}
                  </h3>
                  <p
                    className="experience-header-detail-text"
                    style={{ color: theme.secondaryText }}
                  >
                    {t(experience["description"])}
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          <ExperienceAccordion
            sections={experience["sections"]}
            theme={theme}
          />
          <StudiesSection 
          theme={theme}
          />
          <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
          <TopButton theme={this.props.theme} />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Experience);
