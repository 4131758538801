import React from "react";
import ProjectLanguages from "../../components/projectLanguages/ProjectLanguages";
import "./GithubRepoCard.css";
import { Fade } from "react-reveal";
import { withTranslation } from "react-i18next";

function GithubRepoCard({ repo, theme, t }) {
  const imageUrl = require(`../../assests/images/${repo.image}`);


  return (
    <div className="repo-card-div" style={{ backgroundColor: theme.highlight }}>
      <Fade bottom duration={2000} distance="40px">
        <div className="project-content">
          <div className="project-img">
            <img alt={t(`projects.${repo.id}.name`)} src={imageUrl} />
          </div>

          <div
            className="repo-container"
            key={repo.id}
            
          >
            <div className="repo-name-div">
              <p className="repo-name" style={{ color: theme.text }}>
                {t(`projects.${repo.id}.name`)}
              </p>
            </div>
            <p className="repo-description" style={{ color: theme.text }}>
              {t(`projects.${repo.id}.description`)}
            </p>
            <div className="repo-details">
              <p
                className="repo-creation-date subTitle"
                style={{ color: theme.secondaryText }}
              >
                {t("projects.createdOn")} {repo.createdAt.split("T")[0]}
              </p>
              <ProjectLanguages
                className="repo-languages"
                logos={repo.languages}
              />
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default withTranslation()(GithubRepoCard);
