import React from "react";
import { Flip } from "react-reveal";
import { useTranslation } from "react-i18next";
import "./GovermantCareer.css";

const GovermantCareerCard = ({ careers }) => {
  const { t } = useTranslation();

  return (
    <div className="career-card-container">
      <div className="career-card-content">
        {careers.career_elem.map((career) => (
          <Flip bottom duration={2000} key={career.title}>
            <div className="career-card-elem">
              {career.image_path ? (
                <img
                  className="career-card-img"
                  src={require(`../../assests/images/${career.image_path}`)}
                  alt={t(career.title)}
                />
              ) : null}
              <h1 className="card-header-text">{t(career.title)}</h1>
              <p className="card-text">{t(career.description)}</p>
            </div>
          </Flip>
        ))}
      </div>
    </div>
  );
};

export default GovermantCareerCard;
