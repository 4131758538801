import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
// import Button from "../../components/button/Button";
import { Fade } from "react-reveal";
import Divider from "../../components/divider/divider";
import { useTranslation } from "react-i18next";

export default function Greeting(props) {
  const theme = props.theme;
  const { t } = useTranslation();

  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="greeting-text">{t("greetingTitle")}</h1>

              {t("greetingSubjectName") && (
                <h4 className="greeting-subjectname">
                  ( {t("greetingSubjectName")} )
                </h4>
              )}
              <p className="greeting-text-p subTitle">
                {t("greetingSubTitle")}
              </p>
              <SocialMedia theme={theme} />
            </div>
          </div>
          <div className="greeting-image-div">
            <div className="img-container">
              <img
                alt="saad sitting on table"
                src={require("../../assests/images/mainphoto.png")}
              ></img>
              {/* <Carousel images={images} interval={4000} /> */}
            </div>
            {/* <FeelingProud theme={theme} /> */}
          </div>
        </div>
        <h1 className="greeting-back-title">{t("greetingBackTitle")}</h1>
      </div>
      <Divider />
      <div className="video-header-container">
            <h1 className="video-header-text" style={{ color: theme.text }}>
              {t('greeting.videoHeading')}
            </h1>
          </div>

          {/* MP4 Video */}
          <div className="video-container">
            <video
              className="intro-video"
              controls
              autoPlay
              muted
              src={require("../../assests/images/Disku.mp4")}
            >
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="yt-heading-text-container">
  <a href="https://www.youtube.com/live/GC9jsbYXXmM?si=Es0YZfu0IaiRvIA3" target="_blank" rel="noopener noreferrer">
    <h1 className="yt-heading-text" style={{ color: theme.text }}>
      {t('greeting.ytHeading')}
    </h1>
  </a>
</div>

          {/* YouTube Button */}
          <div className="youtube-button-container">
            <a
              className="youtube-button"
              href="https://www.youtube.com/live/GC9jsbYXXmM?si=Es0YZfu0IaiRvIA3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../../assests/images/ytlogo.png")}
                alt="YouTube"
                className="youtube-logo"
              />
            </a>
          </div>
    </Fade>
    
  );
  
}
