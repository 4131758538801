import React from "react";
import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";
import "./ExperienceAccordion.css";
import { withTranslation } from "react-i18next";

function ExperienceAccordion({ sections, theme, t }) {
  return (
    <div className="experience-accord">
      {sections.map((section) => (
        <div className="section" key={section.title}>
          <h2
            className="section-title"
            style={{ color: theme.text }}
          >
            {t(section.title)}
          </h2>
          <div className="experiences-list">
            {section.experiences.map((experience, index) => (
              <ExperienceCard
                key={index}
                index={index}
                totalCards={section.experiences.length}
                experience={experience}
                theme={theme}
                t={t}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default withTranslation()(ExperienceAccordion);
