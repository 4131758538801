import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Educations from "../../containers/education/Educations";
import Certifications from "../../containers/certifications/Certifications";
import Careers from "../../containers/career/Career";
import Divider from "../../components/divider/divider";
import { certifications, images } from "../../portfolio";
import "./EducationComponent.css";
import { Fade } from "react-reveal";
import Carousel from "../../components/carousel/carousel";
import { withTranslation } from "react-i18next";

class Education extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;

    return (
      <div className="education-main">
        <Header theme={this.props.theme} />
        <div className="basic-education">
          <Fade bottom duration={2000} distance="40px">
            <div className="heading-div">
              <div className="heading-img-div">
                <Carousel images={images} interval={4000} />
              </div>
              <div className="heading-text-div">
                <h1 className="heading-text" style={{ color: theme.text }}>
                  {t("education.headingText")}
                </h1>
                <h3 className="heading-sub-text" style={{ color: theme.text }}>
                  {t("education.subText1")}
                </h3>
                <h3 className="heading-sub-text" style={{ color: theme.text }}>
                  {t("education.subText2")}
                </h3>
              </div>
            </div>
          </Fade>
          <Divider></Divider>
          <Educations theme={this.props.theme} />
          <Divider></Divider>
          {certifications.certifications.length > 0 ? (
            <Certifications theme={this.props.theme} />
          ) : null}
          <Careers theme={this.props.theme} />
        </div>
        <Footer theme={this.props.theme} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default withTranslation()(Education);
