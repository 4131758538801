import React from 'react';
import { useTranslation } from 'react-i18next';
import './StudiesSection.css';
import Divider from '../divider/divider';


const StudiesSection = () => {
  const { t } = useTranslation();

  // Define the studies data
  const studies = [
    {
      category: t('superintelligentTechnologicalCivilization.category'),
      studies: [
        t('superintelligentTechnologicalCivilization.studies.0'),
        t('superintelligentTechnologicalCivilization.studies.1'),
        t('superintelligentTechnologicalCivilization.studies.2'),
        t('superintelligentTechnologicalCivilization.studies.3')
      ]
    },
    {
      category: t('hiddenPower.category'),
      studies: [
        t('hiddenPower.studies.0'),
        t('hiddenPower.studies.1'),
        t('hiddenPower.studies.2'),
        t('hiddenPower.studies.3'),
        t('hiddenPower.studies.4')
      ]
    },
    {
      category: t('humanSociety.category'),
      studies: [
        t('humanSociety.studies.0'),
        t('humanSociety.studies.1'),
        t('humanSociety.studies.2'),
        t('humanSociety.studies.3'),
        t('humanSociety.studies.4'),
        t('humanSociety.studies.5'),
        t('humanSociety.studies.6'),
        t('humanSociety.studies.7'),
        t('humanSociety.studies.8'),
        t('humanSociety.studies.9')
      ]
    },
    {
      category: t('universe.category'),
      studies: [
        t('universe.studies.0'),
        t('universe.studies.1'),
        t('universe.studies.2'),
        t('universe.studies.3'),
        t('universe.studies.4')
      ]
    },
    {
      category: t('physicalEvidence.category'),
      studies: [
        t('physicalEvidence.studies.0'),
        t('physicalEvidence.studies.1'),
        t('physicalEvidence.studies.2'),
        t('physicalEvidence.studies.3'),
        t('physicalEvidence.studies.4'),
        t('physicalEvidence.studies.5'),
        t('physicalEvidence.studies.6')
      ]
    }
  ];

  let studyNumber = 1; // Initialize the study number counter

  return (
    <section className="studies-section">
      <h1 className="section-title">{t('fullStudyListHeader')}</h1>
      {studies.map((studyCategory, index) => (
        <div key={index} className="study-category">
          <h2 className="category-title">{studyCategory.category}</h2>
          <ul>
            {studyCategory.studies.map((study, idx) => (
              <li key={idx} className="study-item">
                <span className="study-number">STUDY {studyNumber++}:</span> "{study}"
              </li>
            ))}
          </ul>
          {index < studies.length - 1 && <Divider />} {/* Add a divider between categories */}
        </div>
      ))}
    </section>
  );
};

export default StudiesSection;
