import React from "react";
import { Fade, Flip } from "react-reveal";
import { useTranslation } from "react-i18next";
import "./DegreeCard.css";

const DegreeCard = ({ degree, theme }) => {
  const { t } = useTranslation();

  return (
    <div className="degree-card">
      {degree.logo_path && (
        <Flip left duration={2000}>
          <div className="card-img">
            <img
              className="card-img-elem"
              src={require(`../../assests/images/${degree.logo_path}`)}
              alt={degree.alt_name}
            />
          </div>
        </Flip>
      )}
      <Fade right duration={2000} distance="40px">
        <div
          className="card-body"
          style={{ width: degree.logo_path ? "90%" : "100%" }}
        >
          <div
            className="body-header"
            style={{ backgroundColor: theme.headerColor }}
          >
            <div className="body-header-title">
              <h2 className="card-title" style={{ color: theme.text }}>
                {t(degree.title)}
              </h2>
              <h3 className="card-subtitle" style={{ color: theme.text }}>
                {t(degree.subtitle)}
              </h3>
            </div>
            <div className="body-header-duration">
              <h3 className="duration" style={{ color: theme.text }}>
                {t(degree.duration)}
              </h3>
            </div>
          </div>
          <div className="body-content">
            {degree.descriptions.map((sentence, index) => (
              <p
                key={index}
                className="content-list"
                style={{ color: theme.text }}
              >
                {t(sentence)}
              </p>
            ))}
            {degree.website_link && (
              <a
                href={degree.website_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* Render a visit button or link if needed */}
              </a>
            )}
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default DegreeCard;
