import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import { withTranslation } from "react-i18next";
import Divider from "../../components/divider/divider";

class SkillSection extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;
    return (
      <div>
        {skills.data.map((skill, index) => (
          <div key={index}>
            <div key={index} className="skills-main-div">
              {skill.imagePath ? (
                <Fade left duration={2000}>
                  <div className="skills-image-div">
                    <img
                      alt={t(skill.title)}
                      src={`../../assests/images/${skill.imagePath}`}
                    />
                  </div>
                </Fade>
              ) : null}

              <div className={`skills-text-div ${!skill.imagePath ? 'centered-text' : ''}`}>
                <Fade right duration={1000}>
                  <h1
                    className="skills-heading"
                    style={{ color: theme.text }}
                  >
                    {t(skill.title)}
                  </h1>
                </Fade>
                <Fade right duration={1500}>
                  <SoftwareSkill logos={skill.softwareSkills} />
                </Fade>
                <Fade right duration={2000}>
                  <div>
                    {skill.skills.map((skillSentence, index) => (
                      <p
                        key={index}
                        className="subTitle skills-text"
                        style={{ color: theme.secondaryText }}
                      >
                        {t(skillSentence)}
                      </p>
                    ))}
                  </div>
                  {/* <Divider /> */}
                </Fade>
              </div>
            </div>
            <Divider />
          </div>
        ))}
      </div>
    );
  }
}

export default withTranslation()(SkillSection);
