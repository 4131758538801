import React from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import "./CertificationCard.css";

const CertificationCard = ({ certificate, theme }) => {
  const { t } = useTranslation();

  return (
    <Fade bottom duration={2000} distance="20px">
      <div className="cert-card">
        <div className="content">
          <div to="" target="_blank" rel="noopener noreferrer">
            <div className="content-overlay"></div>
            <div
              className="cert-header"
              style={{ backgroundColor: certificate.color_code }}
            >
              <img
                className="logo_img"
                src={require(`../../assests/images/${certificate.logo_path}`)}
                alt={certificate.alt_name}
              />
            </div>
            <div className="content-details fadeIn-top">
              <h3 className="content-title" style={{ color: theme.body }}>
                {t("certification.professionAndStudy")}
              </h3>
            </div>
          </div>
        </div>
        <div className="cert-body">
          <h2 className="cert-body-title" style={{ color: theme.text }}>
            {t(`certificates.title.${certificate.title}`)}
          </h2>
          <h3
            className="cert-body-subtitle"
            style={{ color: theme.secondaryText }}
          >
            {t(`certificates.subtitle.${certificate.subtitle}`)}
          </h3>
        </div>
      </div>
    </Fade>
  );
};

export default CertificationCard;
