import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { withTranslation } from "react-i18next";
import AddressImg from "./AddressImg";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { contactPageData } from "../../portfolio.js";

const ContactData = contactPageData.contactSection;
const blogSection = contactPageData.blogSection;
const addressSection = contactPageData.addressSection;
const phoneSection = contactPageData.phoneSection;

class Contact extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;
    return (
      <div>
        <Header theme={theme} />
        <div className="contact-main">
          <div className="basic-contact">
            <Fade bottom duration={1000} distance="40px">
              <div className="contact-heading-div">
                <div className="contact-heading-img-div">
                  <img
                    src={require(`../../assests/images/${ContactData["profile_image_path"]}`)}
                    alt=""
                  />
                </div>
                <div className="contact-heading-text-div">
                  <h1
                    className="contact-heading-text"
                    style={{ color: theme.text }}
                  >
                    {t("ContactData.title")}
                  </h1>
                  <p
                    className="contact-header-detail-text subTitle"
                    style={{ color: theme.secondaryText }}
                  >
                    {t("ContactData.description")}
                  </p>
                  <SocialMedia theme={theme} />
                  {/* <div className="resume-btn-div">
                  <Button
                    text="See My Resume"
                    newTab={true}
                    href={greeting.resumeLink}
                    theme={theme}
                  />
                </div> */}
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000} distance="40px">
              <div className="blog-heading-div">
                <div className="blog-heading-text-div">
                  <h1
                    className="blog-heading-text"
                    style={{ color: theme.text }}
                  >
                    {t("blogSection.title")}
                  </h1>
                  <p
                    className="blog-header-detail-text subTitle"
                    style={{ color: theme.secondaryText }}
                  >
                    {t("blogSection.subtitle")}
                  </p>
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000} distance="40px">
              <div className="address-heading-div">
                <div className="contact-adress-img-div">
                  {/* <img
											src={require(`../../assests/images/${addressSection["avatar_image_path"]}`)}
											alt=""
										/> */}
                  {/* <AddressImg theme={theme} /> */}

                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.774297736467!2d19.44691487685004!3d41.31377340055678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134fda4a3ff9bfef%3A0xd1ee706d8b7a20a7!2sRruga%20Egnatia%2C%20Durr%C3%ABs%2C%20Albania!5e0!3m2!1sen!2s!4v1725008666726!5m2!1sen!2s"
                    width="100%"
                    height="500px"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="address-heading-text-div">
                  <h1
                    className="address-heading-text"
                    style={{ color: theme.text }}
                  >
                    {t("addressSection.title")}
                  </h1>
                  <p
                    className="contact-header-detail-text subTitle"
                    style={{ color: theme.secondaryText }}
                  >
                    {t("addressSection.subtitle")}
                  </p>
                  <h1
                    className="address-heading-text"
                    style={{ color: theme.text }}
                  >
                    {t("phoneSection.title")}
                  </h1>
                  <p
                    className="contact-header-detail-text subTitle"
                    style={{ color: theme.secondaryText }}
                  >
                    {t("phoneSection.subtitle")}
                  </p>
                  <div className="address-btn-div"></div>
                </div>
              </div>
            </Fade>
          </div>
          <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
          <TopButton theme={this.props.theme} />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Contact);
