/* Change this file to get your personal Portfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

// SEO Related settings
const seo = {
  title: "Perparim Asllan Zaimi",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Perparim Zaimi's Portfolio",
    type: "website",
    url: "http://perparimzaimi.com/",
  },
};

// Home Page
const greeting = {
  title: "greetingTitle",
  logo_name: "greetingLogoName",
  subjectname: "greetingSubjectName",
  nickname: "greetingNickname",
  backtitle: "greetingBackTitle",
  subTitle: "greetingSubTitle",
  resumeLink: "greetingResumeLink",
  portfolio_repository: "greetingPortfolioRepository",
  githubProfile: "greetingGithubProfile",
};

const socialMediaLinks = [
  {
    name: "YouTube",
    link: "https://youtube.com/c/pzaimidu",
    fontAwesomeIcon: "fa-youtube",
    backgroundColor: "#FF0000",
  },
  {
    name: "Gmail",
    link: "mailto:pzaimidu@gmail.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  {
    name: "X-Twitter",
    link: "https://x.com/perparim_zaimi",
    fontAwesomeIcon: "fa-x-twitter",
    backgroundColor: "#000000",
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/perparim.zaimi.3",
    fontAwesomeIcon: "fa-facebook-f",
    backgroundColor: "#1877F2",
  },
];

// Carousel Images
const images = [
  "../../assests/images/slideimage8.jpg",
  "../../assests/images/slideimage1.jpg",
  "../../assests/images/slideimage2.jpg",
  "../../assests/images/slideimage3.jpg",
  "../../assests/images/slideimage4.jpg",
  "../../assests/images/slideimage5.jpg",
  "../../assests/images/slideimage6.jpg",
  "../../assests/images/slideimage7.jpg",
];
const skills = {
  data: [
    {
      title: "skills.secretPower.title",
      fileName: "DataScienceImg",
      imagePath: "",
      skills: [
        "skills.secretPower.description.0",
        "skills.secretPower.description.1",
        "skills.secretPower.description.2",
      ],
      softwareSkills: [],
    },
    {
      title: "skills.superIntelligentCivilization.title",
      fileName: "DataScienceImg",
      imagePath: "",
      skills: [
        "skills.superIntelligentCivilization.description.0",
        "skills.superIntelligentCivilization.description.1",
        "skills.superIntelligentCivilization.description.2",
      ],
      softwareSkills: [],
    },
    {
      title: "skills.forTheUniverse.title",
      fileName: "DataScienceImg",
      imagePath: "",
      skills: [
        "skills.forTheUniverse.description.0",
        "skills.forTheUniverse.description.1",
      ],
      softwareSkills: [],
    },
    {
      title: "skills.holyMountainTomorr.title",
      fileName: "DataScienceImg",
      imagePath: "tomori.jpg",
      skills: [
        "skills.holyMountainTomorr.description.0",
        "skills.holyMountainTomorr.description.1",
        "skills.holyMountainTomorr.description.2",
        "skills.holyMountainTomorr.description.3",
      ],
      softwareSkills: [],
    },
  ],
};

// About Me Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

// Resume Data
const degrees = {
  degrees: [
    {
      title: "naimFrasheriHighSchoolTitle",
      subtitle: "naimFrasheriHighSchoolSubtitle",
      logo_path: "",
      alt_name: "naimFrasheriHighSchoolAltName",
      duration: "naimFrasheriHighSchoolDuration",
      descriptions: ["naimFrasheriHighSchoolDescription1"],
      website_link: "https://durres.gov.al/",
    },
    {
      title: "higherNavalAcademyOfVloraTitle",
      subtitle: "higherNavalAcademyOfVloraSubtitle",
      logo_path: "shkolla.jpg",
      alt_name: "higherNavalAcademyOfVloraAltName",
      duration: "higherNavalAcademyOfVloraDuration",
      descriptions: ["higherNavalAcademyOfVloraDescription1"],
      website_link:
        "https://military-history.fandom.com/wiki/Naval_Academy_(Albania)",
    },
    {
      title: "mechanicalEngineeringTitle",
      subtitle: "mechanicalEngineeringSubtitle",
      logo_path: "engineer.png",
      alt_name: "mechanicalEngineeringAltName",
      duration: "mechanicalEngineeringDuration",
      descriptions: ["mechanicalEngineeringDescription1"],
      website_link:
        "https://military-history.fandom.com/wiki/Naval_Academy_(Albania)",
    },
    {
      title: "completedUniversityStudiesTitle",
      subtitle: "completedUniversityStudiesSubtitle",
      logo_path: "engin.png",
      alt_name: "completedUniversityStudiesAltName",
      duration: "completedUniversityStudiesDuration",
      descriptions: [
        "completedUniversityStudiesDescription1",
        "completedUniversityStudiesDescription2",
        "completedUniversityStudiesDescription3",
      ],
      website_link:
        "https://military-history.fandom.com/wiki/Naval_Academy_(Albania)",
    },
  ],
};

// Certifications
const certifications = {
  certifications: [
    {
      title: "captain_of_vessel",
      subtitle: "captain_of_vessel_subtitle",
      logo_path: "mscship.jpg",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "captain_and_expert_in_fishing_operations",
      color_code: "#D83B0199",
    },
    {
      title: "lecturer_of_marine_sciences",
      subtitle: "lecturer_of_marine_sciences_subtitle",
      logo_path: "aleksander.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "lecturer_of_marine_sciences",
      color_code: "#1F70C199",
    },
    {
      title: "captain_and_expert_in_fishing_operations",
      subtitle: "captain_and_expert_in_fishing_operations_subtitle",
      logo_path: "msc.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "captain_and_expert_in_fishing_operations",
      color_code: "#D83B0199",
    },
    {
      title: "expert_in_aquaculture_and_mitiliculture",
      subtitle: "expert_in_aquaculture_and_mitiliculture_subtitle",
      logo_path: "aquaculture.jpg",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "aquaculture_and_mitiliculture",
      color_code: "#1F70C199",
    },
    {
      title: "author_of_several_studies_on_fishing",
      subtitle: "author_of_several_studies_on_fishing_subtitle",
      logo_path: "studies.jpg",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "author_of_several_studies",
      color_code: "#000000",
    },
    {
      title: "explorer_of_new_fishing_areas",
      subtitle: "explorer_of_new_fishing_areas_subtitle",
      logo_path: "explorer of fishing area.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
      alt_name: "explorer_of_new_fishing_areas",
      color_code: "#1F70C199",
    },
    {
      title: "biomass_estimator",
      subtitle: "biomass_estimator_subtitle",
      logo_path: "biomass.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "biomass_estimator",
      color_code: "#0C9D5899",
    },
    {
      title: "chairman_of_government_group",
      subtitle: "chairman_of_government_group_subtitle",
      logo_path: "Map-of-Albania-and-its-Exclusive-Economic-Zone-EEZ.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "chairman_of_government_group",
      color_code: "#bec1c2",
    },
    {
      title: "member_of_albanian_french_expedition",
      subtitle: "member_of_albanian_french_expedition_subtitle",
      logo_path: "french.jpeg",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "member_of_albanian_french_expedition",
      color_code: "#2A73CC",
    },
    {
      title: "member_of_map_compliance_committee",
      subtitle: "member_of_map_compliance_committee_subtitle",
      logo_path: "Map.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "member_of_map_compliance_committee",
      color_code: "#4285F499",
    },
  ],
};

// __Govermant_Career_Data__
const career = {
  title: "career.title",
  career_elem: [
    {
      title: "career.career_elem.0.title",
      image_path: "peshkimi.jpeg",
      description: "career.career_elem.0.description",
    },
    {
      title: "career.career_elem.1.title",
      image_path: "",
      description: "career.career_elem.1.description",
    },
    {
      title: "career.career_elem.2.title",
      image_path: "",
      description: "career.career_elem.2.description",
    },
    {
      title: "career.career_elem.3.title",
      image_path: "",
      description: "career.career_elem.3.description",
    },
    {
      title: "career.career_elem.4.title",
      image_path: "",
      description: "career.career_elem.4.description",
    },
  ],

  // some other data here
};

// Studies Data Page
const experience = {
  title: "experience.title",
  subtitle: "experience.subtitle",
  description: "experience.description",
  header_image_path: "about.jpg",
  sections: [
    {
      title: "experience.sections.moreStudies.title",
      work: true,
      experiences: [
        {
          title: "experience.sections.moreStudies.experiences.0.title",
          logo_path: "eye.png",
          description:
            "experience.sections.moreStudies.experiences.0.description",
          color: "#000000",
        },
        {
          title: "experience.sections.moreStudies.experiences.1.title",
          logo_path: "superintel.png",
          description:
            "experience.sections.moreStudies.experiences.1.description",
          color: "#0879bf",
        },
        {
          title: "experience.sections.moreStudies.experiences.2.title",
          logo_path: "message.png",
          description:
            "experience.sections.moreStudies.experiences.2.description",
          color: "#9b1578",
        },
        {
          title: "experience.sections.moreStudies.experiences.3.title",
          logo_path: "hiddenpower.png",
          description:
            "experience.sections.moreStudies.experiences.3.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.4.title",
          logo_path: "images (6).jfif",
          description:
            "experience.sections.moreStudies.experiences.4.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.5.title",
          logo_path: "images (3).jfif",
          description:
            "experience.sections.moreStudies.experiences.5.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.6.title",
          logo_path: "images (2).jfif",
          description:
            "experience.sections.moreStudies.experiences.6.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.7.title",
          logo_path: "angel.jpg",
          description:
            "experience.sections.moreStudies.experiences.7.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.8.title",
          logo_path: "pub2.jpg",
          description:
            "experience.sections.moreStudies.experiences.8.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.9.title",
          logo_path: "images (9).jfif",
          description:
            "experience.sections.moreStudies.experiences.9.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.10.title",
          logo_path: "mars.webp",
          description:
            "experience.sections.moreStudies.experiences.10.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.11.title",
          logo_path: "future.jpg",
          description:
            "experience.sections.moreStudies.experiences.11.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.12.title",
          logo_path: "jesus-the-creator.jpg",
          description:
            "experience.sections.moreStudies.experiences.12.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.13.title",
          logo_path: "linear-a.jpg",
          description:
            "experience.sections.moreStudies.experiences.13.description",
          color: "#fc1f20",
        },
        {
          title: "experience.sections.moreStudies.experiences.14.title",
          logo_path: "earth.jpg",
          description:
            "experience.sections.moreStudies.experiences.14.description",
          color: "#fc1f20",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "",
  description: "",
  avatar_image_path: "animated_ashutosh.jpg",
};

const publicationsHeader = {
  title: "",
  description: "",
  avatar_image_path: "",
};

const publications = {
  data: [
    {
      id: "",
      name: "",
      createdAt: "",
      description: "",
      url: "",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.jpg",
    description:
      "I am available on almost every social media. You can message me and I will try to help you and answer your questions.",
  },
  blogSection: {
    title: "",
    subtitle: "",
    link: "",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Contact Info",
    subtitle: "",
    locality: "",
    country: "",
    region: "",
    postalCode: "",
    streetAddress: "",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/KYsVw6undJ32f96K7",
  },
  phoneSection: {
    title: "+355 69 880 4883",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
  images,
  career,
};
