import React from "react";
import { Fade } from "react-reveal";
import { useTranslation } from "react-i18next";
import GovernmentCareerCard from "../../components/govermantcareer/GovermantCareer";
import { career } from "../../portfolio";
import "./Career.css";

const Careers = () => {
  const { t } = useTranslation();
  return (
    <div className="career-card-main">
      <Fade bottom duration={2000}>
        <h1 className="career-heading-title">{t("career.title")}</h1>
        <GovernmentCareerCard careers={career} />
      </Fade>
    </div>
  );
};

export default Careers;
