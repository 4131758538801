import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Divider from "../../components/divider/divider";
import ProcessCard from "../../components/processCard/ProcessCard";
import "./PublicationInProcess.css";

function PublicationInProcess({ theme, onToggle }) {
  const { t } = useTranslation();

  const books = t("publication.books", { returnObjects: true }).slice(0, 2);
  const processes = t("publication.processes", { returnObjects: true }) || [];
  const phrase = t("publication.phrase");

  return (
    <div className="opensource-main">
      <Header theme={theme} />

      <div className="background-section">
        <h1 className="section-heading">{t("publication.booksInProcess")}</h1>

        <div className="book-covers">
          <div className="book-info">
            {books.map((book, index) => (
              <div key={index} className="book-info">
                <h2>{book.title}</h2>
              </div>
            ))}
          </div>
          <img
            src="/assests/images/mainphoto.png"
            alt="Author"
            className="author-image"
          />
        </div>

        <p className="phrase">{phrase}</p>
      </div>

      <Divider />

      <div className="section process-section">
        <h2 className="section-heading">{t("publication.topics")}</h2>

        <div>
          {Array.isArray(processes) &&
            processes.map((process, index) => (
              <ProcessCard
                key={index}
                topic={process.topic}
                title={process.title}
                subtitle={process.subtitle}
                abstractHeading={process.abstractHeading}
                abstract={process.abstract}
                keywordsHeading={process.keywordsHeading}
                keywords={process.keywords}
                image={process.image}
              />
            ))}
        </div>
      </div>

      <Footer theme={theme} onToggle={onToggle} />
      <TopButton theme={theme} />
    </div>
  );
}

export default PublicationInProcess;
