import React from "react";
import "./Header.css";
import { IoLanguage } from "react-icons/io5";
import { IoChevronDownOutline } from "react-icons/io5";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { greeting, settings } from "../../portfolio.js";
import SeoHeader from "../seoHeader/SeoHeader";
import Divider from "../../components/divider/divider";
import { useTranslation } from "react-i18next";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

const Header = (props) => {
  const theme = props.theme;
  const link = settings.isSplash ? "/splash" : "home";
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = () => {
    const newLanguage = i18n.language === "en" ? "al" : "en";
    i18n.changeLanguage(newLanguage);
  };
  // Dropdown
  const triggerDropdownMenu = (event) => {
    const menuTrigger = event.currentTarget;

    menuTrigger.parentElement.classList.toggle("is-active");
    // menuTrigger.parentElement.classList.toggle("active ");

    const menuItems = menuTrigger.nextElementSibling.firstElementChild.children;

    for (let i = 0; i < menuItems.length; i++) {
      const item = menuItems[i];

      item.addEventListener("click", () => {
        menuTrigger.parentElement.classList.remove("is-active");
      });
    }
  };

  return (
    <div className="nav-fixed">
      <Fade top duration={1000} distance="20px">
        <SeoHeader />
        <div>
          <header className="header">
            <NavLink to={link} tag={Link} className="logo">
              <span style={{ color: theme.text }}> &lt;</span>
              <span className="logo-name" style={{ color: theme.text }}>
                {t(greeting.logo_name)}
              </span>
              <span style={{ color: theme.text }}>/&gt;</span>
            </NavLink>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
              <span className="navicon"></span>
            </label>
            <ul className="menu" style={{ backgroundColor: theme.body }}>
              <li>
                <NavLink
                  to="/home"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("header.home")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/aboutme"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("header.aboutMe")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/studies"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("header.studies")}
                </NavLink>
              </li>
              <li>
                <div className="dropdown-container">
                  <div className="dropdown languages-dorpdown">
                    <div
                      className="dropdown-trigger"
                      onClick={triggerDropdownMenu}
                    >
                      <div className="button">{t("header.pub")}</div>
                    </div>

                    <div className="dropdown-menu" id="dropdown-menu">
                      <div className="dropdown-content">
                        <div className="dropdown-item">
                          <NavLink
                            to="/publication-in-process"
                            tag={Link}
                            activeStyle={{ fontWeight: "bold" }}
                            style={{ color: theme.text }}
                            onMouseEnter={(event) =>
                              onMouseEnter(event, theme.highlight)
                            }
                            onMouseOut={(event) => onMouseOut(event)}
                          >
                            {t("header.publication-in-process")}
                          </NavLink>
                        </div>
                        <div className="dropdown-item">
                          <NavLink
                            to="/publications"
                            tag={Link}
                            activeStyle={{ fontWeight: "bold" }}
                            style={{ color: theme.text }}
                            onMouseEnter={(event) =>
                              onMouseEnter(event, theme.highlight)
                            }
                            onMouseOut={(event) => onMouseOut(event)}
                          >
                            {t("header.publications")}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <NavLink
                  to="/publications"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("header.publications")}
                </NavLink> */}
              </li>
              <li>
                <NavLink
                  to="/scientificactivity"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("header.scientificActivity")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  tag={Link}
                  activeStyle={{ fontWeight: "bold" }}
                  style={{ color: theme.text }}
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  {t("header.contactMe")}
                </NavLink>
              </li>
              <li>
                <button
                  onClick={handleChangeLanguage}
                  className="language-switcher-button"
                  onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
                  onMouseOut={(event) => onMouseOut(event)}
                >
                  <IoLanguage className="language-icon" />
                  {i18n.language === "en"
                    ? t("header.languageSwitcher")
                    : t("header.languageSwitcher")}
                  <IoChevronDownOutline className="chevron-icon" />
                </button>
              </li>
            </ul>
          </header>
          <Divider />
        </div>
      </Fade>
    </div>
  );
};

export default Header;
