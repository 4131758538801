import React, { Component } from "react";
import "./PullRequests.css";
import { Fade } from "react-reveal";
import PullRequestCard from "../../components/pullRequestCard/PullRequestCard";
import { withTranslation } from "react-i18next";
import pullRequestsData from "../../shared/opensource/pull_requests.json";
import myImage from "../../assests/images/malitomorrit1.png";

class PullRequests extends Component {
  render() {
    const { t } = this.props;
    const theme = this.props.theme;

    return (
      <div>
        <div className="pull-requests-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="pull-requests-header" style={{ color: theme.text }}>
              {t("pullRequests.header")}
            </h1>
          </Fade>
        </div>
        <div className="pull-requests-intro">
          <p style={{ color: theme.text }}>{t("pullRequests.intro")}</p>
        </div>
        <div className="pull-request-body-div">
          {pullRequestsData["data"].map((pullRequest) => {
            const title = t(`pullRequests.titles.${pullRequest.titleKey}`);
            return (
              <PullRequestCard
                key={pullRequest.id}
                pullRequest={{ ...pullRequest, title }}
              />
            );
          })}
        </div>
        <div className="pull-requests-image">
          <img src={myImage} alt="Mali i shenjtë i Tomorrit" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(PullRequests);
