import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import GithubRepoCard from "../../components/githubRepoCard/GithubRepoCard";
import TopButton from "../../components/topButton/TopButton";
import { withTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import {
  projectsHeader,
} from "../../portfolio.js";
import ProjectsData from "../../shared/opensource/projects.json";
import "./Projects.css";

class Projects extends Component {
  render() {
    const theme = this.props.theme;
    const { t } = this.props;

    return (
      <div className="projects-main">
        <Header theme={theme} />
        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="projects-heading-div">
              <div className="projects-heading-img-div">
                <img
                  src={require(`../../assests/images/${projectsHeader["avatar_image_path"]}`)}
                  alt=""
                />
              </div>
              <div className="projects-heading-text-div">
                <h1 className="projects-heading-text" style={{ color: theme.text }}>
                  {t("projectsHeader.title")}
                </h1>
                <div
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                  dangerouslySetInnerHTML={{
                    __html: t("projectsHeader.description").replace(/\n/g, "<br />"),
                  }}
                ></div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="repo-cards-div-main">
          {ProjectsData.data.map((repo, index) => (
            <GithubRepoCard key={index} repo={repo} theme={theme} />
          ))}
        </div>

        <div className="academic-books-section">
  <h2 style={{ color: theme.text, textAlign: "center", marginBottom: "20px" }}>
    {t("academicBooks.title")}
  </h2>
  <div className="academic-books-content">
    <div className="academic-books-column">
      <h3 style={{ color: theme.text }}>{t("academicBooks.universityLevelTitle")}</h3>
      <ul style={{ color: theme.secondaryText }}>
        <li>{t("academicBooks.book1")}</li>
        <li>{t("academicBooks.book2")}</li>
        <li>{t("academicBooks.book3")}</li>
      </ul>
    </div>
    <div className="academic-books-column">
      <h3 style={{ color: theme.text }}>{t("academicBooks.highSchoolTitle")}</h3>
      <ul style={{ color: theme.secondaryText }}>
        <li>{t("academicBooks.book4")}</li>
        <li>{t("academicBooks.book5")}</li>
        <li>{t("academicBooks.book6")}</li>
      </ul>
    </div>
  </div>
  <div className="academic-books-covers">
            <img src={require("../../assests/images/book1.png")} alt="Book Cover 1" className="book-cover" />
            <img src={require("../../assests/images/book2.png")} alt="Book Cover 2" className="book-cover" />
            <img src={require("../../assests/images/book3.png")} alt="Book Cover 3" className="book-cover" />
            <img src={require("../../assests/images/book4.png")} alt="Book Cover 4" className="book-cover" />
          </div>
</div>


        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default withTranslation()(Projects);