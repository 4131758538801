import React from "react";
import "./ProcessCard.css";

function ProcessCard({
  topic,
  title,
  abstractHeading,
  abstract,
  keywordsHeading,
  keywords,
  image,
}) {
  const keywordArray = Array.isArray(keywords)
    ? keywords
    : keywords.split(", ");

  return (
    <div className="process-card">
      <div className="process-content">
        <div className="process-text">
          <h2 className="process-title">{topic}</h2>
          <p className="process-topic">{title}</p>
          <h3 className="process-abstract-heading">{abstractHeading}</h3>
          <div>
            {image && <img src={image} alt={title} className="process-image" />}
            <p className="process-abstract">{abstract}</p>
            <h4 className="process-keywords-heading">{keywordsHeading}</h4>
            <p className="process-keywords">{keywordArray.join(", ")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessCard;
